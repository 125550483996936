import React from 'react';

export const NavLink = ({ href, children }) => {
  return (
    <a
      href={href}
      className="flex items-center rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </a>
  );
};
