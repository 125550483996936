import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function ErrorPage({ errorCode, heading, description }) {
  const { t } = useTranslation(['report']);
  const navigate = useNavigate();
  return (
    <div className="h-screen">
      <Helmet>
        <title> {errorCode} Error | DataMakelaar</title>
        <meta
          name="description"
          content="Sorry, it seems you've landed on a page that doesn't exist. We apologize for the inconvenience and invite you to explore our website further to find what you're looking for. Alternatively, please contact our support team for assistance."
        />
        <meta
          name="keywords"
          content="buy house, Netherlands, machine learning, property valuation, bidding strategy, real estate investment, market insights, property worth, dream home, technology"
        />
      </Helmet>
      <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
        <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
          <div className="max-w-lg">
            <p className="text-base font-semibold leading-8 text-blue-600">
              {errorCode}
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {heading}
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {description}
            </p>
            <div className="mt-10">
              <button
                onClick={() => {
                  navigate('/');
                }}
                className="text-sm font-semibold leading-7 text-blue-600"
              >
                <span aria-hidden="true">&larr;</span>{' '}
                {t('ErrorBackToHomeTitle')}
              </button>
            </div>
          </div>
        </main>
        <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
          <img
            src="https://images.unsplash.com/photo-1604999804186-202fb601fa93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            alt=""
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  );
}
