import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../UI/Skeleton';
import { floorLevelTransformer } from '../../utils/floorLevelTransformer';

export const Report = ({ data, children }) => {
  const { t } = useTranslation(['report']);

  const {
    addressData: { street, number, postcode, neighborhood, city },
    apartmentType,
    floorsNumber,
    houseArea,
    floorLocation,
    energyLabel,
    lotArea,
  } = data;

  return (
    <div className="bg-[#e5e9ea]">
      {/* Header */}
      <div className="relative bg-gray-800 pb-32">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1584003564911-a7a321c84e1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1368&q=80"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-4xl">
            {t('ReportTitle')}{' '}
            <span className="text-[#07C3F6]">
              {street} {number}
            </span>
          </h1>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-16 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg md:mx-4 ">
            <div className="px-4 py-5 sm:px-6">
              {street && number ? (
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {street} {number}
                </h3>
              ) : (
                <Skeleton length={100} />
              )}
              {postcode && neighborhood && city ? (
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {postcode}, {neighborhood}, {city}
                </p>
              ) : (
                <Skeleton length={100} />
              )}
            </div>
            <div className="border-t-2 border-gray-300 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('ReportFeaturesType')}
                  </dt>
                  {apartmentType ? (
                    <dd className="mt-1 text-sm text-gray-900">
                      {apartmentType}
                    </dd>
                  ) : (
                    <Skeleton length={100} />
                  )}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('ReportFeaturesHouseArea')}
                  </dt>
                  {houseArea ? (
                    <dd className="mt-1 text-sm text-gray-900">
                      {houseArea === '–' ? '-' : `${houseArea} m²`}
                    </dd>
                  ) : (
                    <Skeleton length={100} />
                  )}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('ReportFeaturesLOTArea')}
                  </dt>
                  {lotArea ? (
                    <dd className="mt-1 text-sm text-gray-900">
                      {lotArea === '–' ? '-' : `${lotArea} m²`}
                    </dd>
                  ) : (
                    <Skeleton length={100} />
                  )}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('ReportFeaturesNumberOfFloors')}
                  </dt>
                  {floorsNumber ? (
                    <dd className="mt-1 text-sm text-gray-900">
                      {floorsNumber}
                    </dd>
                  ) : (
                    <Skeleton length={100} />
                  )}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('ReportFeaturesFloorLocation')}
                  </dt>
                  {floorLocation ? (
                    <dd className="mt-1 text-sm text-gray-900">
                      {floorLevelTransformer(floorLocation)}
                    </dd>
                  ) : (
                    <Skeleton length={100} />
                  )}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('ReportFeaturesEnergyLabel')}
                  </dt>
                  {energyLabel ? (
                    <dd className="mt-1 text-sm text-gray-900">
                      {energyLabel}
                    </dd>
                  ) : (
                    <span> – </span>
                  )}
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
