import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Footer } from '../components/UI/Footer';
import { Header } from '../components/UI/Header';
import { HouseOverview } from '../components/SearchResult/HouseOverview';

import { fetchAddressOverview, fetchFeatures } from '../utils/reportCalls';

export default function Search(props) {
  const { t } = useTranslation(['overview']);
  let { bagId } = useParams();

  const [data, setData] = useState({
    addressData: {},
    apartmentType: '-',
    houseArea: '-',
    lotArea: '-',
    floorsNumber: '-',
    floorLocation: '-',
    energyLabel: '-',
  });

  const fetchData = async () => {
    const AddressOverviewResult = await fetchAddressOverview(bagId);

    if (Object.keys(AddressOverviewResult)[0] !== 'config') {
      setData((prevState) => ({
        ...prevState,
        addressData: { ...AddressOverviewResult },
      }));
    }
    const features = await fetchFeatures(bagId);
    Object.keys(features).map((feature) => {
      if (feature === 'construction_type_residential_building_a0') {
        setData((prevState) => ({
          ...prevState,
          apartmentType:
            features.construction_type_residential_building_a0.value,
        }));
      }
      if (feature === 'construction_type_apartment_a0') {
        setData((prevState) => ({
          ...prevState,
          apartmentType: features.construction_type_apartment_a0.value,
        }));
      }
      if (feature === 'measurements_living') {
        setData((prevState) => ({
          ...prevState,
          houseArea: features.measurements_living.value,
        }));
      }
      if (feature === 'measurements_lot_area') {
        setData((prevState) => ({
          ...prevState,
          lotArea: features.measurements_lot_area.value,
        }));
      }
      if (feature === 'layout_number_floors') {
        setData((prevState) => ({
          ...prevState,
          floorsNumber: features.layout_number_floors.value,
        }));
      }
      if (feature === 'layout_situated_floor') {
        setData((prevState) => ({
          ...prevState,
          floorLocation: features.layout_situated_floor.value,
        }));
      }
      if (feature === 'energy_label') {
        setData((prevState) => ({
          ...prevState,
          energyLabel: features.energy_label.value,
        }));
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Helmet>
        <title> {t('OverviewSEOTitle')} | DataMakelaar</title>
        <meta
          name="description"
          content="Ensure the accuracy of your real estate investment with our convenient property overview page. Confirm that you're looking at the right house with ease, and make informed decisions when buying your dream home. Trust in our user-friendly platform to simplify the home-buying process."
        />
        <meta
          name="keywords"
          content="buy house, Netherlands, machine learning, property valuation, bidding strategy, real estate investment, market insights, property worth, dream home, technology"
        />
      </Helmet>
      <main>
        <HouseOverview data={data} bagId={bagId} />
      </main>
      <Footer />
    </>
  );
}
