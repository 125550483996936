import * as React from 'react';
import { Footer } from '../components/UI/Footer';
import { Header } from '../components/UI/Header';
import { Hero } from '../components/Home/Hero';
import { PrimaryFeatures } from '../components/Home/PrimaryFeatures';
import { About } from '../components/Home/About';
// import { Pricing } from '../components/Home/Pricing';
import { Contact } from '../components/Home/Contact';

export default function Home() {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <About />
        {/* <Pricing /> */}
        <Contact />
      </main>
      <Footer />
    </>
  );
}
