import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Overview from './pages/Overview';
import Report from './pages/Report';
import ErrorPage from './pages/ErrorPage';
import { AuthenticationGuard } from './components/authentication/authentication-guard';
import { Helmet } from 'react-helmet';
import { useAnalytics } from './components/analytics/useAnalytics';

function App() {
  useAnalytics();
  return (
    <Suspense fallback={null}>
      <main className="column">
        <Helmet>
          <title>DataMakelaar</title>
          <meta
            name="description"
            content="Maximize your chances of getting the best value for your money when buying a house in the Netherlands with the help of machine learning. Leverage this cutting-edge technology to evaluate the property's worth and develop a winning bidding strategy."
          />
          <meta
            name="keywords"
            content="buy house, Netherlands, machine learning, property valuation, bidding strategy, real estate investment, market insights, property worth, dream home, technology"
          />
        </Helmet>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search">
            <Route
              path=":bagId"
              element={<AuthenticationGuard component={Overview} />}
            />
            <Route
              path=":bagId/report"
              element={<AuthenticationGuard component={Report} />}
            />
          </Route>
          <Route
            path="/*"
            element={
              <ErrorPage
                errorCode="404"
                heading="Page not found"
                description="Sorry, we couldn’t find the page you’re looking for."
              />
            }
          />
        </Routes>
      </main>
    </Suspense>
  );
}

export default App;
