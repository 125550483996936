import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSelect = () => {
  useEffect(() => {
    console.log('refreshed', localStorage.getItem('i18nextLng'));
  }, []);
  const { i18n } = useTranslation(['common', 'home']);

  return (
    <select
      id="location"
      name="location"
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      defaultValue={localStorage.getItem('i18nextLng')}
      onChange={(e) => {
        i18n.changeLanguage(e.target.value);
      }}
    >
      <option value="en">EN</option>
      <option value="nl">NL</option>
    </select>
  );
};
