import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Listbox, Transition, Combobox } from '@headlessui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const cities = [
  { id: 1, name: 'Amsterdam', value: 'amsterdam' },
  { id: 2, name: 'Rotterdam', value: 'rotterdam' },
  { id: 3, name: 'Utrecht', value: 'utrecht' },
];

export const CustomCombobox = () => {
  const { t } = useTranslation(['home']);
  const navigate = useNavigate();

  const [query, setQuery] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedCity, setSelectedCity] = useState(cities[0]);
  const [selectedAddress, setSelectedAddress] = useState({});

  const fetchAddresses = async () => {
    return axios
      .get(
        `https://api.datamakelaar.com/v1/core/search?query=${query}&city=${selectedCity.value}&only_available=1`,
      )
      .then((el) => {
        if (!el.data.addresses) {
          setAddresses([]);
          return;
        }
        setAddresses(el.data.addresses);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchAddresses();
  }, [query]);

  useEffect(() => {
    if (
      selectedAddress.id &&
      selectedAddress.display_name &&
      selectedAddress.available
    ) {
      navigate(`/search/${selectedAddress.id}`);
    }
  }, [selectedAddress]);

  return (
    <>
      <Listbox value={selectedCity} onChange={setSelectedCity}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full md:w-[10rem] cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                <span className="block truncate">{selectedCity.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm md:text-left">
                  {cities.map((city) => (
                    <Listbox.Option
                      key={city.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        )
                      }
                      value={city}
                    >
                      {({ selectedCity, active }) => (
                        <>
                          <span
                            className={classNames(
                              selectedCity ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {city.name}
                          </span>

                          {selectedCity ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <Combobox
        as="div"
        value={selectedAddress}
        onChange={setSelectedAddress}
        className="flex-auto mt-5 sm:mt-0 sm:ml-2"
      >
        <div className="relative mt-1">
          <Combobox.Input
            placeholder={t('homeHeroComboboxPlaceholder')}
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(address) => address?.display_name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {addresses.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {addresses.map((address) => (
                <Combobox.Option
                  key={address.id}
                  value={address}
                  onClick={() => {
                    if (address.available) {
                      // navigate(`/search/${address.id}`);
                    }
                  }}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                            address.available ? 'bg-green-400' : 'bg-gray-200',
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            'ml-3 truncate',
                            selected && 'font-semibold',
                          )}
                        >
                          {address.display_name}
                          <span className="sr-only">
                            {' '}
                            is {address.available ? 'online' : 'offline'}
                          </span>
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
};
