import axios from 'axios';

const URL = 'https://api.datamakelaar.com/v1/core/object/';

export const fetchAddressOverview = async (address_id) => {
  const searchUrl = `${URL}${address_id}`;
  return await axios
    .get(searchUrl)
    .then((res) => {
      return {
        city: res.data.address.city,
        fullAddress: res.data.address.display,
        neighborhood: res.data.address.neighborhood_wijk,
        number: res.data.address.number,
        postcode: res.data.address.postcode,
        state: res.data.address.state,
        street: res.data.address.street,
      };
    })
    .catch((err) => {
      return err;
    });
};

export const fetchFeatures = async (address_id) => {
  const searchUrl = `${URL}${address_id}/features/measurements_living,measurements_lot_area,construction_type_apartment_a0,construction_type_residential_building_a0,layout_number_floors,layout_situated_floor,energy_label`;

  const type = await axios.get(searchUrl).then((data) => {
    return data;
  });
  return type.data.features;
};

export const fetchReportData = async (address_id, accessToken) => {
  const config = {
    url: `https://api.datamakelaar.com/v1/insights/object/${address_id}?top=10`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axios(config).then(({ data }) => {
    return data;
  });
};

export const fetchReportComparables = async (
  address_id,
  insight_id,
  accessToken,
) => {
  const config = {
    url: `https://api.datamakelaar.com/v1/insights/object/${address_id}/${insight_id}/comparables?features=measurements_living`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axios(config).then(({ data }) => {
    return data;
  });
};

export const fetchReportInsights = async (city, neighborhood, accessToken) => {
  const insightsConfig = {
    url: `https://api.datamakelaar.com/v1/insights/market/${city}/${neighborhood}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axios(insightsConfig)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return;
    });
};

export const fetchReportEvents = async (address_id, accessToken) => {
  const eventsConfig = {
    url: `https://api.datamakelaar.com/v1/core/object/${address_id}/events`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axios(eventsConfig)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return;
    });
};
