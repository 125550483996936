import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

export const AuthButton = ({ href, children }) => {
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0();
  const { t } = useTranslation(['common']);

  return (
    <>
      {isAuthenticated ? (
        <button
          className="min-w-fit font-semibold inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
          onClick={() => logout()}
        >
          {t('HeaderNavigationSignIn')}
        </button>
      ) : (
        <button
          className="min-w-fit font-semibold inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
          onClick={() => loginWithRedirect({})}
        >
          {t('HeaderNavigationSignOut')}
        </button>
      )}
    </>
  );
};
