export const floorLevelTransformer = (floorLevel) => {
  switch (floorLevel) {
    case '1':
      return 'Ground floor';
    case '2':
      return 'First floor';
    case '3':
      return 'Second floor';
    case '4':
      return 'Third floor';
    default:
      return floorLevel;
  }
};
