import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

export const MarketInsightsReport = ({ data, report, buurt }) => {
  const { t } = useTranslation(['report']);
  let city;
  let neighborhood;
  if (report) {
    city = report.object.address.city;
    neighborhood = report.object.address.neighborhood_wijk;
  }
  let neighborhoodAndCityData = [];
  let listedVsSold = [];
  let daysUntilSale = [];
  if (data && data.price_sqm) {
    neighborhoodAndCityData = data.price_sqm.map((el) => {
      return {
        year: `Q${el.quarter} ${el.year}`,
        [city]: el.city,
        [neighborhood]: el.neighborhood,
      };
    });
  }
  if (data && data.list_sold_quantity) {
    listedVsSold = data.list_sold_quantity.map((el) => {
      return {
        date_start: `Q${el.quarter} ${el.year}`,
        Sold: el.sold_count,
        Listed: el.listed_count,
      };
    });
  }
  if (data && data.days_until_sale) {
    daysUntilSale = data.days_until_sale.map((el) => {
      return {
        year: `Q${el.quarter} ${el.year}`,
        [city]: el.city,
        [neighborhood]: el.neighborhood,
      };
    });
  }

  if (data) {
    return (
      <section
        className="mx-auto max-w-7xl px-4 pb-32 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h1 className="px-4 sm:px-6 lg:px-6 pb-4 text-lg font-semibold leading-6 text-gray-900">
          {t('ReportMarketInsightsTitle')} {buurt}?
        </h1>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg md:mx-4 py-10">
          {/* 1 */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700 font-semibold">
                  {t('ReportMarketInsightsSubtitle1')}
                </p>
              </div>
            </div>

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
              {neighborhoodAndCityData.length > 0 ? (
                <div className="md:flex md:items-center h-96 w-full p-4">
                  <h1 className="hidden md:block text-base font-semibold text-gray-900 ">
                    €/{t('ReportComparablesReportColumn3')}
                  </h1>
                  <ResponsiveContainer
                    height="100%"
                    className="w-full md:w-[95%]"
                  >
                    <LineChart
                      width={500}
                      height={300}
                      data={neighborhoodAndCityData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey={city}
                        stroke="#00509d"
                        activeDot={{ r: 8 }}
                      />
                      <Line
                        type="monotone"
                        dataKey={neighborhood}
                        stroke="#9BA9B5"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <p className="mt-2 p-5 text-center text-sm text-gray-800">
                  {t('ReportMarketInsightsEmptyTitle')}
                </p>
              )}
            </div>
          </div>

          {/* 2 */}
          <div className="px-4 sm:px-6 lg:px-8 mt-16">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700 font-semibold">
                  {t('ReportMarketInsightsSubtitle2')}
                </p>
              </div>
            </div>
            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
              {daysUntilSale.length > 0 ? (
                <div className="md:flex md:items-center h-96 w-full p-4">
                  <h1 className="hidden md:block text-base font-semibold text-gray-900 ">
                    {t('ReportMarketInsightsDaysTitle')}
                  </h1>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={daysUntilSale}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey={city} fill="#00509d" />
                      <Bar dataKey={neighborhood} fill="#9BA9B5" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <p className="mt-2 p-5 text-center text-sm text-gray-800">
                  {t('ReportMarketInsightsEmptyTitle')}
                </p>
              )}
            </div>
          </div>

          {/* 3 */}
          <div className="px-4 sm:px-6 lg:px-8 mt-16">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700 font-semibold">
                  {t('ReportMarketInsightsSubtitle3')}
                </p>
              </div>
            </div>
            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
              {listedVsSold.length > 0 ? (
                <div className="md:flex md:items-center h-96 w-full p-4">
                  <ResponsiveContainer
                    height="100%"
                    className="w-full md:w-[95%]"
                  >
                    <BarChart
                      width={500}
                      height={300}
                      data={listedVsSold}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date_start" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Listed" fill="#9BA9B5" />
                      <Bar dataKey="Sold" fill="#00509d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <p className="mt-2 p-5 text-center text-sm text-gray-800">
                  {t('ReportMarketInsightsEmptyTitle')}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
};
