import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Footer } from '../components/UI/Footer';
import { Header } from '../components/UI/Header';
import { Report } from '../components/Report/Report';
import { ValuationCard } from '../components/Report/ValuationCard';
import { BiddingStrategyCard } from '../components/Report/BiddingStrategyCard';
import { ComparablesReport } from '../components/Report/ComparablesReport';
import { MarketInsightsReport } from '../components/Report/MarketInsightsReport';
import { EventsReport } from '../components/Report/EventsReport';
import {
  fetchAddressOverview,
  fetchFeatures,
  fetchReportData,
  fetchReportComparables,
  fetchReportInsights,
  fetchReportEvents,
} from '../utils/reportCalls';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

const ReportPage = () => {
  const { t } = useTranslation(['report']);
  const { getAccessTokenSilently } = useAuth0();
  let { bagId } = useParams();

  const [data, setData] = useState({
    addressData: {},
    apartmentType: '-',
    houseArea: '-',
    lotArea: '-',
    floorsNumber: '-',
    floorLocation: '-',
    energyLabel: '-',
  });

  const [report, setReport] = useState();

  const [valuationData, setValuationData] = useState([
    { name: t('ReportValuationTitle'), value: undefined },
    { name: `${t('ReportValuationTitle')}/m²`, value: undefined },
  ]);

  const [biddingData, setBiddingData] = useState([
    { name: `${t('ReportBiddingConservative')} (20%)`, value: undefined },
    { name: `${t('ReportBiddingNeutral')} (50%)`, value: undefined },
    { name: `${t('ReportBiddingAggressive')} (75%)`, value: undefined },
  ]);

  const [comparables, setComparables] = useState([]);
  const [events, setEvents] = useState([]);
  const [marketInsights, setMarketInsights] = useState([]);

  const formatNumber = (number) => {
    if (number && number !== NaN && number !== Infinity) {
      const nf = new Intl.NumberFormat();
      return nf.format(Math.floor(number));
    }
  };

  const calculateBiddingStrategy = (value, percentage) => {
    return Math.floor(value * (1 + percentage));
  };

  const fetchData = async () => {
    const AddressOverviewResult = await fetchAddressOverview(bagId);

    if (Object.keys(AddressOverviewResult)[0] !== 'config') {
      setData((prevState) => ({
        ...prevState,
        addressData: { ...AddressOverviewResult },
      }));
    }

    const features = await fetchFeatures(bagId);
    Object.keys(features).map((feature) => {
      if (feature === 'construction_type_residential_building_a0') {
        setData((prevState) => ({
          ...prevState,
          apartmentType:
            features.construction_type_residential_building_a0.value,
        }));
      }
      if (feature === 'construction_type_apartment_a0') {
        setData((prevState) => ({
          ...prevState,
          apartmentType: features.construction_type_apartment_a0.value,
        }));
      }
      if (feature === 'measurements_living') {
        setData((prevState) => ({
          ...prevState,
          houseArea: features.measurements_living.value,
        }));
      }
      if (feature === 'measurements_lot_area') {
        setData((prevState) => ({
          ...prevState,
          lotArea: features.measurements_lot_area.value,
        }));
      }
      if (feature === 'layout_number_floors') {
        setData((prevState) => ({
          ...prevState,
          floorsNumber: features.layout_number_floors.value,
        }));
      }
      if (feature === 'layout_situated_floor') {
        setData((prevState) => ({
          ...prevState,
          floorLocation: features.layout_situated_floor.value,
        }));
      }
      if (feature === 'energy_label') {
        setData((prevState) => ({
          ...prevState,
          energyLabel: features.energy_label.value,
        }));
      }
    });
    const accessToken = await getAccessTokenSilently();
    const reportData = await fetchReportData(bagId, accessToken);
    setReport(reportData);

    const reportComparables = await fetchReportComparables(
      reportData.object.id,
      reportData.insight_id,
      accessToken,
    );

    setComparables(reportComparables.data);

    const reportEvents = await fetchReportEvents(
      reportData.object.id,
      accessToken,
    );
    setEvents(reportEvents.events);

    const dataMarketInsights = await fetchReportInsights(
      reportData.object.address.city,
      reportData.object.address.neighborhood_wijk,
      accessToken,
    );

    setMarketInsights(dataMarketInsights);

    setValuationData([
      {
        name: t('ReportValuationTitle'),
        value: formatNumber(
          Math.round(
            reportData.insight.valuation.pred_transaction_price / 1000,
          ) * 1000,
        ),
      },
      {
        name: `${t('ReportValuationTitle')}/m²`,
        value: formatNumber(
          reportData.insight.valuation.pred_transaction_price / data.houseArea,
        ),
      },
    ]);

    setBiddingData([
      {
        name: `${t('ReportBiddingConservative')} (20%)`,
        value: formatNumber(
          Math.round(
            calculateBiddingStrategy(
              reportData.insight.valuation.pred_transaction_price,
              reportData.insight.bidding_strat.p50,
            ) / 1000,
          ) * 1000,
        ),
      },
      {
        name: `${t('ReportBiddingNeutral')} (50%)`,
        value: formatNumber(
          Math.round(
            calculateBiddingStrategy(
              reportData.insight.valuation.pred_transaction_price,
              reportData.insight.bidding_strat.p75,
            ) / 1000,
          ) * 1000,
        ),
      },
      {
        name: `${t('ReportBiddingAggressive')} (75%)`,
        value: formatNumber(
          Math.round(
            calculateBiddingStrategy(
              reportData.insight.valuation.pred_transaction_price,
              reportData.insight.bidding_strat.p95,
            ) / 1000,
          ) * 1000,
        ),
      },
    ]);
  };

  useEffect(() => {
    fetchData();
  }, [data.houseArea]);

  return (
    <>
      <Header />
      <Helmet>
        <title>{t('ReportSEOTitle')} | DataMakelaar</title>
        <meta
          name="description"
          content="Gain valuable insights and make informed decisions when buying a property with our comprehensive report page. Our machine learning technology provides a detailed valuation, bidding strategy, comparable transactions, and market insights for your peace of mind. Trust in our advanced analytics to guide you towards a successful real estate investment."
        />
        <meta
          name="keywords"
          content="buy house, Netherlands, machine learning, property valuation, bidding strategy, real estate investment, market insights, property worth, dream home, technology"
        />
      </Helmet>
      <main className="bg-[#e5e9ea]">
        <Report data={data} />
        <ValuationCard
          title={t('ReportValuationTitle')}
          stats={valuationData}
        />
        <EventsReport data={events} />
        <BiddingStrategyCard stats={biddingData} />

        <ComparablesReport
          data={comparables}
          city={data.addressData['city'] && data.addressData.city}
        />
        <MarketInsightsReport
          data={marketInsights}
          report={report}
          buurt={data.addressData.neighborhood}
        />
      </main>
      <Footer />
    </>
  );
};

export default withAuthenticationRequired(ReportPage);

export const Head = () => <title>Valuation Report</title>;
