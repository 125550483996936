import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AuthenticationGuard = ({ component }) => {
  const { t } = useTranslation(['authentication']);
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <main className="relative isolate min-h-screen">
        <img
          src="https://images.unsplash.com/photo-1534247124888-eb6acbc70ba2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
          <p className="text-base font-semibold leading-8 text-gray-800">
            {t('AuthenticationGuardTitle')}
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-800 sm:text-5xl">
            {t('AuthenticationGuardHeader')}
          </h1>
          <p className="mt-4 text-base text-gray-800/70 sm:mt-6">
            {t('AuthenticationGuardSubtitle')}
          </p>

          <div className="flex items-center justify-center space-x-2 mt-8">
            <div className="w-3 h-3 rounded-full animate-pulse dark:bg-blue-400"></div>
            <div className="w-3 h-3 rounded-full animate-pulse dark:bg-blue-400"></div>
            <div className="w-3 h-3 rounded-full animate-pulse dark:bg-blue-400"></div>
          </div>
        </div>
      </main>
    ),
  });

  return <Component />;
};
