import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import logo from '../../images/logo-full.png';
import { LanguageSelect } from './LanguageSelect';

import { Container } from './Container';
import { NavLink } from './NavLink';
import { AuthButton } from './AuthButton';

const MobileNavLink = ({ href, children }) => {
  return (
    <a href={href}>
      <Popover.Button className="block w-full p-2">{children}</Popover.Button>
    </a>
  );
};

const MobileNavIcon = ({ open }) => {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  );
};

const MobileNavigation = () => {
  const { t } = useTranslation(['common']);
  const { pathname } = useLocation();

  const isHome = pathname === '/';
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {isHome && (
              <>
                <MobileNavLink href="#features">
                  {t('HeaderNavigationFeatures')}
                </MobileNavLink>
                <MobileNavLink href="#about">
                  {t('HeaderNavigationAbout')}
                </MobileNavLink>
                {/* <MobileNavLink href="#pricing">Pricing</MobileNavLink> */}
                <MobileNavLink href="#contact">
                  {t('HeaderNavigationContact')}
                </MobileNavLink>
              </>
            )}
            <LanguageSelect />
            <AuthButton />
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
};

export const Header = () => {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')?.length > 2) {
      i18next.changeLanguage('nl');
    }
  }, []);

  return (
    <header className="py-2">
      <Container>
        <nav className="relative z-50">
          <div className="flex items-center justify-between md:gap-x-12">
            <button onClick={() => navigate('/')}>
              <img src={logo} alt="DataMakelaar logo" className="h-14" />
            </button>
            <div className=" hidden md:flex md:gap-x-8">
              {isHome && (
                <>
                  <NavLink href="#features">
                    {t('HeaderNavigationFeatures')}
                  </NavLink>
                  <NavLink href="#about">{t('HeaderNavigationAbout')}</NavLink>
                  {/* <NavLink href="#pricing">Pricing</NavLink> */}
                  <NavLink href="#contact">
                    {t('HeaderNavigationContact')}
                  </NavLink>
                </>
              )}
              <AuthButton />
              <LanguageSelect />
            </div>
            <div className="md:hidden flex items-center gap-x-5 md:gap-x-8">
              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
};
