import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ComparablesReport = ({ data, city }) => {
  const { t } = useTranslation(['report']);
  const comparables = data.filter((el) => el.address.city === city);
  const formatNumber = (number) => {
    if (number && number !== NaN && number !== Infinity) {
      const nf = new Intl.NumberFormat();
      return nf.format(Math.floor(number));
    }
  };

  return (
    <section
      className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 lg:px-8"
      aria-labelledby="contact-heading"
    >
      <h1 className="px-4 sm:px-6 lg:px-6 pb-4 text-lg font-semibold leading-6 text-gray-900">
        {t('ReportComparablesReportTitle')}
      </h1>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg md:mx-4 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                {t('ReportComparablesReportSubtitle')}
              </h1>
            </div>
          </div>
          <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {t('ReportComparablesReportColumn1')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {t('ReportComparablesReportColumn2')}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {t('ReportComparablesReportColumn3')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t('ReportComparablesReportColumn4')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {comparables.map((transaction, idx) => (
                  <tr key={transaction.id}>
                    <td
                      className={classNames(
                        idx === 0 ? '' : 'border-t border-gray-200',
                        'sm:pl-6 pl-4 px-3 py-3.5 text-xs sm:text-sm text-gray-500',
                      )}
                    >
                      {transaction.address.street} {transaction.address.number}
                    </td>
                    <td
                      className={classNames(
                        idx === 0 ? '' : 'border-t border-gray-200',
                        ' px-3 py-3.5 text-xs sm:text-sm text-gray-500 lg:table-cell',
                      )}
                    >
                      {transaction.transactions[0].date}
                    </td>
                    <td
                      className={classNames(
                        idx === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-xs sm:text-sm text-gray-500 lg:table-cell',
                      )}
                    >
                      {transaction.features.measurements_living.value}
                    </td>
                    <td
                      className={classNames(
                        idx === 0 ? '' : 'border-t border-gray-200',
                        'px-3 py-3.5 text-xs sm:text-sm text-gray-500',
                      )}
                    >
                      € {formatNumber(transaction.transactions[0].amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
