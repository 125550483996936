import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const EventsReport = ({ data }) => {
  const { t } = useTranslation(['report']);
  const formatDate = (date) => {
    let removedTimestamp = date.split('T')[0];
    return removedTimestamp.split('-').reverse().join('-');
  };

  const sortedEvents = data.sort(function (a, b) {
    return new Date(b.events_ts) - new Date(a.events_ts);
  });

  const filteredEvents = sortedEvents.filter(
    (event) =>
      event.type === 'funda_listed' ||
      event.type === 'funda_price_update' ||
      event.type === 'transaction',
  );

  const formatNumber = (number) => {
    if (number && number !== NaN && number !== Infinity) {
      const nf = new Intl.NumberFormat();
      return nf.format(Math.floor(number));
    }
  };

  const selectPrice = (event) => {
    switch (event.type) {
      case 'funda_listed':
        return (
          <>
            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
              {t('ReportEventsReportAskPriceTitle')}
            </span>{' '}
            <span className="mx-2">
              € {formatNumber(event.body.initial_price)}
            </span>
          </>
        );
      case 'funda_price_update':
        return (
          <>
            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
              {t('ReportEventsReportAskPriceTitle')}
            </span>{' '}
            <span className="mx-2">€ {formatNumber(event.body.new_price)}</span>
          </>
        );
      case 'transaction':
        return (
          <>
            <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
              {t('ReportEventsReportSalesPriceTitle')}
            </span>{' '}
            <span className="mx-2">
              € {formatNumber(event.body.transaction_amount)}
            </span>
          </>
        );
      default:
        return '';
    }
  };

  return (
    <section
      aria-labelledby="valuation-card"
      className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 lg:px-8"
    >
      <h1 className="px-4 sm:px-6 lg:px-6 pb-4 text-lg font-semibold leading-6 text-gray-900">
        {t('ReportEventsReportTitle')}
      </h1>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg md:mx-4 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                {t('ReportEventsReportSubtitle')}
              </h1>
            </div>
          </div>
          <dl className="mt-5 ">
            <div className="flow-root">
              <ul role="list" className="space-y-6">
                {filteredEvents.map((event, eventIdx) => (
                  <li key={event.id} className="relative flex gap-x-4">
                    <div
                      className={classNames(
                        eventIdx === filteredEvents.length - 1
                          ? 'h-6'
                          : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center ',
                      )}
                    >
                      <div className="w-px bg-gray-200" />
                    </div>
                    <>
                      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        {event.type === 'paid' ? (
                          <CheckCircleIcon
                            className="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                        )}
                      </div>
                      <div className="flex">
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 ">
                          <span className="font-medium text-gray-900 ">
                            {selectPrice(event)}
                          </span>
                        </p>
                        <time
                          dateTime={event.events_ts}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {formatDate(event.events_ts)}
                        </time>
                      </div>
                    </>
                  </li>
                ))}
                {filteredEvents.length === 0 && (
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    {t('ReportEventsReportLoadingPlaceholder')}
                  </p>
                )}
              </ul>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};
