import React from 'react';
import { useTranslation } from 'react-i18next';

export const Skeleton = ({ length = 150 }) => {
  const { t } = useTranslation(['common']);
  return (
    <div role="status" className="max-w-sm animate-pulse">
      <div
        className={`h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[${length}px] mb-2.5`}
      />
      <span className="sr-only">{t('SkeletonLoading')}...</span>
    </div>
  );
};
