import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/global.css';
import App from './App';
import { Auth0ProviderWithNavigate } from './components/authentication/auth0-provider-with-navigate';

import './i18n.js';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: 'https://www.datamakelaar.nl',
      }}
    >
      <App />
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
);
