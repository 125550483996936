import React from 'react';
import { Skeleton } from '../UI/Skeleton';
import { useTranslation } from 'react-i18next';

export const ValuationCard = ({ title, stats }) => {
  const { t } = useTranslation(['report']);
  return (
    <section
      aria-labelledby="valuation-card"
      className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 lg:px-8"
    >
      <h1 className="px-4 sm:px-6 lg:px-6 pb-4 text-lg font-semibold leading-6 text-gray-900">
        {t('ReportValuationCardTitle')}
      </h1>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg md:mx-4 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                {title}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t('ReportValuationCardDescription')}
              </p>
            </div>
          </div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                {item.value ? (
                  <dd className="mt-1 text-2xl font-semibold tracking-tight text-slim-600">
                    € {item.value}
                  </dd>
                ) : (
                  <div className="pt-5">
                    <Skeleton length={100} />
                  </div>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};
