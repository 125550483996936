import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './Container';

export function Footer() {
  const { t } = useTranslation(['common']);
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6"></div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} DataMakelaar.{' '}
            {t('FooterAllRightsReserved')}
          </p>
        </div>
      </Container>
    </footer>
  );
}
