import * as React from 'react';
import { getIcon } from '../../utils/getIcon';
import { Container } from '../UI/Container';
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t } = useTranslation(['home']);

  const features = [
    {
      name: t('homeAboutFeaturesFirstTitle'),
      description: t('homeAboutFeaturesFirstDescription'),
      icon: getIcon('shoes'),
    },
    {
      name: t('homeAboutFeaturesSecondTitle'),
      description: t('homeAboutFeaturesSecondDescription'),
      icon: getIcon('chip'),
    },
    {
      name: t('homeAboutFeaturesThirdTitle'),
      description: t('homeAboutFeaturesThirdDescription'),
      icon: getIcon('file'),
    },
  ];

  return (
    <Container>
      <div className="bg-white" id="about">
        {/* Header */}
        <div className="relative pb-32">
          <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-black md:text-5xl lg:text-6xl">
              {t('homeAboutTitle')}
            </h1>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            How does DataMakelaar help you price your home?
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {features.map((link) => (
              <div
                key={link.name}
                className="flex flex-col rounded-2xl bg-white shadow-xl"
              >
                <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
                  <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-blue-500 p-5 shadow-lg">
                    {link.icon}
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Container>
  );
};
